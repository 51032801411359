import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./Banner.module.scss";
import { useIntl } from "react-intl";
import routes from "i18n/routeHelper";
import ButtonLink from "components/ButtonLink";
import {
  goalHomepageOnboardingClick
} from "../../../helpers/goalTracking";

const Banner = ({ id, defaultMessage, description }: any) => {
  const intl = useIntl();
  const routesMap = routes[intl.locale];

  const handleOnboardingClick = useCallback(() => {
    goalHomepageOnboardingClick();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.textContainer}>
          <h2 style={{ color: "white", textAlign: "center" }}>
            <FormattedMessage
              id={id}
              defaultMessage={defaultMessage}
              description={description}
            />
          </h2>
          <ButtonLink to={routesMap.download()} onClick={handleOnboardingClick}>
            <FormattedMessage
              defaultMessage="Try today for free"
              description="Try today for free"
              id="generic.action.download"
            />
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default Banner;
